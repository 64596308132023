.as-home-updates-strip {
  margin: 40px 0;
  padding: 40px 0;
  border-top: 1px solid $as-c_main;

  @include respond-to(tablet) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.as-home-updates-strip-box {
  display: flex;

  @include respond-to(tablet) {
    flex-direction: column;
  }
}

.as-home-updates-strip__newsletter-box {
  flex-grow: 1;
  width: 100%;
  padding-left: 40px;

  @include respond-to(tablet) {
    padding-left: 0;
  }
}

.as-home-fb-page {
  z-index: 1;
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: 10px;
  width: 40%;

  @include respond-to(tablet) {
    width: 100%;
    height: 350px;
    margin-top: 20px;
  }

  @include respond-to(handhelds) {
    height: 150px;
  }
}