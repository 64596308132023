.as-calendar-box {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.as-calendar-box__header {
  text-align: center;
  padding: 10px;
  font-size: 1.3em;
  color: #fff;
  background: $as-c_main;
}

.as-calendar-box__title {
  margin: 0;
}

.as-calendar-box__footer {
  z-index: 1;
  position: relative;
  text-align: center;
  padding: 10px 10px 20px;
}

.as-calendar-box__button {
  padding: 5px 30px;
  font-size: 1.2em;
}

// Calendar

#caleandar-container {
  flex-grow: 1;
}

.cld-nav {
  &.cld-fwd {
    &:before {
      font-size: 1.8em;
      line-height: 0.5em;
      content: '›';
    }
  }
  &.cld-rwd {
    &:before {
      font-size: 1.8em;
      line-height: 0.5em;
      content: '‹';
    }
  }
}

.cld-labels {
  color: $as-c_main;
}

.cld-day {
  min-height: 45px;
  &.today {
    background: lighten($as-c_main, 47.5%);
  }
  &.eventday {
    font-weight: 700;
    color: $as-c_main_dark;
  }

  &:hover {
    background: lighten($as-c_main, 47.5%);
  }

  &.prevMonth, &.nextMonth {
    cursor: auto;
    background: none;
  }
}

.cld-title {
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.cld-number {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}