.as-page-heading {
  margin-bottom: 20px;
}

.as-page-heading__title {
  margin: 0;
  font-size: 2.5em;
  line-height: 0.8em;

  @include respond-to(medium-screens) {
    font-size: 2em;
  }

  @include respond-to(handhelds) {
    font-size: 1.5em;
  }
}

.as-page-heading__subtitle {
  margin: 0;
  color: #6f6f6f;
  font-weight: 400;
}