.as-scroll-box {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100%;

  &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
  }
  
  &::-webkit-scrollbar-track {
      background: #d6d6d6;
  }
  
  &::-webkit-scrollbar-thumb {
      background: #B8B8B8;
  }
}