.as-item-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.as-item-card__image-wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 105%;

  @include respond-to(tablet) {
    padding-bottom: 70%;
  }
}

.as-item-card__image {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translate(-50%, 0);
}

.as-item-card__box {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px 10px;
  text-align: center;

  @include respond-to(tablet) {
    height: auto;
  }
}

.as-item-card__title {
  font-weight: 700;
  font-size: 1.6em;
  color: $as-c_main;
}

.as-item-card__content {
  flex-grow: 1;
  margin: 10px 0;
  font-weight: 600;
  color: #606060;
}

.as-item-card__btn {
  align-self: center;
  padding: 3px 15px;
}