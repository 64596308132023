.as-mobile__hide {
  @include respond-to(medium-screens) {
    display: none !important;
  }
}

.as-mobile__show {
  display: none !important;
  @include respond-to(medium-screens) {
    display: block !important;
  }
}

.as-mobile__order_first {
  @include respond-to(medium-screens) {
    order: -1;
  }
}