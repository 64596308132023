.as-socials-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  margin: 15px;

  @include respond-to(tablet) {
    display: none;
  }
}

.as-socials-menu__item {
  margin: 4px 0;
  text-align: center;
  font-size: 1.1em;
  color: #fff;
  background: $as-c_main_dark;
  @include circle-element(30px);
  transition: ease .15s;

  &:hover {
    color: #fff;
    background: darken($as-c_main_dark, 10%);
  }
}