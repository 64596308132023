.as-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 -20px;
}

.as-list-item {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  width: 33.333%;
  padding: 20px;

  @include respond-to(tablet){
    width: 50%;
  }

  @include respond-to(handhelds){
    width: 100%;
  }
}

.as-list-item__date {
  font-size: 1.25em;
  font-weight: 700;
  color: $as-c_font_light;

  @include respond-to(small-tablet){
    font-size: 1.1em;
  }
}

.as-list-item__title {
  font-size: 1.75em;
  color: $as-c_main_dark;

  @include respond-to(small-tablet){
    font-size: 1.5em;
  }
}

.as-list-item__content {
  margin: 25px 0;
  font-size: 1.05em;
  line-height: 1.4em;
  color: darken($as-c_main_dark, 35%);

  @include respond-to(small-tablet){
    font-size: 1em;
  }
}

.as-list-item__image {
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;

  &:before {
    content: '';
    display: block;
    padding-bottom: 55%;
  }

  > img {
    @include center-element;
    width: 100%;
  }
}

.as-list-item__btn {
  font-size: 1.4em;
  color: $as-c_main_dark;

  @include respond-to(small-tablet){
    font-size: 1.3em;
  }

  &:hover {
    > i {
      right: 10px;
    }
  }

  > i {
    position: relative;
    right: 0;
    font-size: 0.8em;
    transition: ease .15s;
  }
}