@include font-face(Flaticon, assets/fonts/Flaticon/Flaticon, normal);

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../assets/fonts/Flaticon/Flaticon.svg#Flaticon") format("svg");
  }
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.fi-link:before { content: "\f100"; }
.fi-multimedia:before { content: "\f101"; }
.fi-interface-3:before { content: "\f102"; }
.fi-google-plus:before { content: "\f103"; }
.fi-drama-masks:before { content: "\f104"; }
.fi-youtube:before { content: "\f105"; }
.fi-logo:before { content: "\f106"; }
.fi-old-couple:before { content: "\f107"; }
.fi-interface-2:before { content: "\f108"; }
.fi-place-localizer:before { content: "\f109"; }
.fi-wrench:before { content: "\f10a"; }
.fi-security-camera:before { content: "\f10b"; }
.fi-verified-document:before { content: "\f10c"; }
.fi-facebook:before { content: "\f10d"; }
.fi-suitcase:before { content: "\f10e"; }
.fi-crawling-baby-silhouette:before { content: "\f10f"; }
.fi-call-answer:before { content: "\f110"; }
.fi-menu:before { content: "\f111"; }
.fi-twitter:before { content: "\f112"; }
.fi-flower-with-leaves:before { content: "\f113"; }
.fi-next:before { content: "\f114"; }
.fi-right-arrow:before { content: "\f115"; }
.fi-blocks:before { content: "\f116"; }
.fi-interface-1:before { content: "\f117"; }
.fi-basketball:before { content: "\f118"; }
.fi-wheelchair:before { content: "\f119"; }
.fi-customer-service:before { content: "\f11a"; }
.fi-down-arrow:before { content: "\f11b"; }
.fi-interface:before { content: "\f11c"; }
.fi-technology:before { content: "\f11d"; }
.fi-file:before { content: "\f11e"; }
.fi-user:before { content: "\f11f"; }
.fi-close:before { content: "\f120"; }
.fi-up-arrow:before { content: "\f121"; }
.fi-back:before { content: "\f122"; }
.fi-open-book:before { content: "\f123"; }
.fi-computer-secure:before { content: "\f124"; }

$fi-link: "\f100";
$fi-multimedia: "\f101";
$fi-interface-3: "\f102";
$fi-google-plus: "\f103";
$fi-drama-masks: "\f104";
$fi-youtube: "\f105";
$fi-logo: "\f106";
$fi-old-couple: "\f107";
$fi-interface-2: "\f108";
$fi-place-localizer: "\f109";
$fi-wrench: "\f10a";
$fi-security-camera: "\f10b";
$fi-verified-document: "\f10c";
$fi-facebook: "\f10d";
$fi-suitcase: "\f10e";
$fi-crawling-baby-silhouette: "\f10f";
$fi-call-answer: "\f110";
$fi-menu: "\f111";
$fi-twitter: "\f112";
$fi-flower-with-leaves: "\f113";
$fi-next: "\f114";
$fi-right-arrow: "\f115";
$fi-blocks: "\f116";
$fi-interface-1: "\f117";
$fi-basketball: "\f118";
$fi-wheelchair: "\f119";
$fi-customer-service: "\f11a";
$fi-down-arrow: "\f11b";
$fi-interface: "\f11c";
$fi-technology: "\f11d";
$fi-file: "\f11e";
$fi-user: "\f11f";
$fi-close: "\f120";
$fi-up-arrow: "\f121";
$fi-back: "\f122";
$fi-open-book: "\f123";
$fi-computer-secure: "\f124";