.as-classes-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px 0;
}

.as-classes-menu-item {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 6px;
  color: #fff;
  @include circle-element(110px);
  line-height: auto;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: ease .15s;

  &:hover {
    transform: translate(0, -10px);
    box-shadow: 0 8px 6px 0 rgba(0, 0, 0, 0.2);
  }

  @include respond-to(handhelds) {
    @include circle-element(100px);
    line-height: auto;
  }

  @include respond-to(small-handhelds) {
    @include circle-element(85px);
    line-height: auto;
  }
}

.as-classes-menu-item__icon {
  display: block;
  margin: 0 auto;
  height: 45%;
}

.as-classes-menu-item__label {
  text-align: center;
  font-size: 1.05em;
  line-height: 1em;
  font-weight: 600;
  padding: 0 10px;
}