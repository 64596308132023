.as-border-box {
  position: relative;
  border: 1px solid #fff;
}

.as-border-box__header,
.as-border-box__footer {
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: absolute;
  left: 0;
  width: 100%;

  &:before, &:after {
    content: '';
    border-bottom: 1px solid #fff;
    width: 50%;
  }
}

.as-border-box__header {
    bottom: 100%;
    transform: translate(0, 50%);
}

.as-border-box__footer {
    top: 100%;
    transform: translate(0, -50%);
}