.as-header {
  z-index: 1000;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $as-g__header-height;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  
  @include respond-to(medium-screens) {
    height: $as-g__header-height_mobile;
  }
}

.as-header-wrapper {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  height: 100%;
  width: 100%;
  @include respond-to(medium-screens) {
    align-items: center;
  }
}

.as-header-cell {
  flex: 1;
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 0 10px;

  @include respond-to(medium-wide-screens){ 
    margin: 0 5px;
  }

  @include respond-to(medium-screens){ 
    margin: 0;
  }
}

.as-header-cell_right {
  justify-content: flex-start;
}

.as-header-cell_left {
  justify-content: flex-end;
}

.as-header-cell__item {
  height: 100%;
}

.as-header-logo__wrap {
  overflow: hidden;
  padding: 50px;
  @include circle-element(225px);
  background: #fff;
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.1);
  transform: translate(25px, -75px);

  @include respond-to(medium-screens) {
    padding: 20px;
    @include circle-element(175px);
  }

  @include respond-to(handhelds) {
    padding: 0;
    width: auto;
    height: 100%;
    box-shadow: none;
    line-height: 1em;
    border-radius: 0;
    transform: translate(0, 0);
  }
}

.as-header-logo {
  display: block;
  margin: 0 auto;
  height: 100%;
  transform: translate(0, 30px);
  @include respond-to(medium-screens) {
    transform: translate(0, 50px);
    height: 75%;
  }
  @include respond-to(handhelds) {
    display: inline-block;
    padding: 0 10px;
    height: 100%;
    transform: translate(0, 0);
  }
}

.as-header-menu {
  flex-grow: 1;

  @include respond-to(medium-screens) {
    flex-grow: 0;
    order: 1;
  }
}