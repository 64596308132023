.as-section-separator{
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  margin: 40px 0;

  &:before, &:after {
    content: '';
    border-bottom: 1px solid $as-c_line_light;
    width: 50%;
  }
}