.as-menu {
  display: flex;
  height: 100%;

  @include respond-to(medium-screens){
    z-index: 999;
    overflow: scroll;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background: $as-c_main;
    transform: translate(-100%, 0);
    transition: $easeOutQuint .4s;

    &.as-active {
      transform: translate(0, 0);
    }
  }
}

.as-menu__open-btn,
.as-menu__close-btn {
  cursor: pointer;
  color: #fff;
  background: transparent;
  border: none;
  font-size: 1.5em;
}

.as-menu__open-btn {
  height: 100%;  
  color: $as-c_main;
  padding: 5px 10px;
  transform: rotateY(180deg);
}

.as-menu__close-btn {
  font-size: 1em;
  margin: 9px 0
}

.as-menu__item {
  position: relative;
  height: 100%;
  text-align: center;
  font-size: 0.95em;
  font-weight: 700;

  &:hover {
    .as-menu__item__link {
      color: #fff;
    }
    .as-menu__item__color-strip {
      height: 100%;
    }
  }

  @include respond-to(medium-wide-screens){ 
    padding: 0 10px;
  }

  @include respond-to(medium-screens){
    width: 100%;
    height: auto;
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    &:last-child {
      border-bottom: none;
    }
  }
}

.as-menu__item__link {
  position: relative;
  display: flex;
  justify-content: center;  
  align-items: center;
  white-space: nowrap;
  height: 100%;
  padding: 0 30px;
  font-size: 1.2em;
  font-weight: 600;
  color: $as-c_main_dark;
  transition: ease .15s;

  @include respond-to(medium-wide-screens){ 
    font-size: 1em;
    padding: 5px 10px;
  }

  @include respond-to(medium-screens){
    white-space: pre-wrap;
    width: 100%;
    padding: 10px 10px 10px 40px;
    color: #fff;
  }
}

.as-menu__submenu {
  opacity: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  max-width: 300px;
  min-width: 100%;
  visibility: hidden;
  transform: translate(0, 20px);
  transition: ease .1s;
  transition-delay: .05s;

  @include respond-to(medium-screens){
    position: static;
    max-height: 0px;
    max-width: 100%;
    background: rgba(0, 0, 0, 0.1);
    transform: translate(0, 0);
    transition: all ease .3s, max-height ease .4s;
    transition-delay: 0s;
  }

  .as-menu__item {
    width: 100%;
    min-width: 200px;    
    border-top: 1px solid rgba(255, 255, 255, 0.3);

    &:first-child {
      border-top: none;
    }

    .as-menu__item__link {
      white-space: pre-wrap;
      padding: 10px;

      @include respond-to(medium-screens){
        padding: 10px 10px 10px 40px;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.as-menu__submenu .as-menu__submenu {
  top: 0;
  right: 100%;
}

.as-menu__item:hover > .as-menu__submenu, 
.as-menu__item > .as-menu__submenu.as-active {
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate(0, 0);

  @include respond-to(medium-screens){
    transition-delay: 0s;
    transition: all ease .4s, max-height ease .5s;
  }
}

.as-menu__item:hover > .as-menu__submenu:not(.as-active) {
  @include respond-to(medium-screens){
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    transform: translate(0, 0);
  }
}

.as-menu__item__color-strip {
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  transition: $easeOutQuint .25s;

  @include respond-to(medium-screens){
    display: none;
  }
}

.as-menu-active-btn {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 100%;
  line-height: 40px;
  font-size: 0.9em;

  &.as-active {
    i {
      transform: rotate(180deg);
    }
  }

  i {
    display: block;
    transition: ease .2s;
  }
}