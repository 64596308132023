.as-c_gray {
  color: #707070;
}

@for $i from 1 through length($as-colors){
  .as-c_AC#{$i}{
    color: nth($as-colors, $i);
  }
  .as-bc_AC#{$i}{
    background: nth($as-colors, $i);
  }
}