.as-login-panel {
  color: #fff;
  font-weight: 600;
  font-size: 0.9em;  

  .as-form__label {
    color: #fff;
  }

  a {
    color: #fff;
    font-weight: 700;
    text-decoration: underline;
  }
}