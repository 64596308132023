.as-form__message {
  padding: 10px;
  border: 1px solid $as-c_line_light;
  background: rgba($as-c_line_light, 0.2);

  @include respond-to(small-tablet){
    font-size: 0.9em;
  }

  &.as-success {
    color: darken($as-c_success, 10%);
    border: 1px solid $as-c_success;
    background: rgba($as-c_success, 0.15);
  }

  &.as-error {
    color: darken($as-c_error, 10%);
    border: 1px solid $as-c_error;
    background: rgba($as-c_error, 0.15);
  }
}