.cld-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cld-datetime {
  width: 100%;
  display: flex;

  .today {
    width: 100%;
    margin: auto;
    padding: 0 20px;
    font-weight: 700;
    white-space: nowrap;
    text-align: center;
  }
}

.cld-nav {
  margin: 0;
  width: 100%;
  padding: 8px 0;
  text-align: center;
  font-size: 0.9em;

  &:hover {
    cursor: pointer;
    background: #EDEDED;
  }
}

.cld-labels, .cld-days {
  margin: 0;
  padding: 0; 
}

.cld-labels {
  font-weight: 600;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.cld-days {
  display: flex;
  flex-wrap: wrap;

  .cld-day:nth-child(7n) {
    border-left: none;
  }
}

.cld-label, .cld-day {
  display: inline-block;
  width: 14.28%;
  text-align: center;
}

.cld-label {
  padding: 5px 0;
  border-left: 1px solid #ebebeb;

  &:last-child {
    border-left: none;
  }
}

.cld-day {
  cursor: pointer;
  position: relative;
  margin: 0;
  padding: 3px;
  height: auto;
  min-height: 40px;
  overflow-y: auto;
  border-left: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.cld-day.clickable:hover {
  cursor: pointer;
}

.cld-day.today {
  background: #ddd;
}

.cld-day.disableDay {
  opacity: 0.5;
}

.cld-day.nextMonth, .cld-day.prevMonth {
  opacity: 0.33;
}

.cld-number {
  margin: 0;
}