.as-news-box {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.as-news-box__header {
  text-align: center;
  padding: 10px;
  font-size: 1.3em;
  color: #fff;
  background: $as-c_main;
}

.as-news-box__title {
  margin: 0;
}

.as-news-box-list {
  position: relative;
  height: 100%;
  min-height: 275px;
  padding: 0 10px 20px;
}

.as-news-box-list-item {
  padding: 10px 0;
  border-bottom: 1px solid #ddd; 
  @include clearfix;

  &:last-child {
    border-bottom: none;
  }
}

.as-news-box-list-item__title {
  margin-bottom: 10px;
  color: $as-c_main;
  font-size: 1.25em;
  font-weight: 700;
}

.as-news-box-list-item__content {
  margin: 0;
}

.as-news-box-list-item__btn {
  float: left;
  color: $as-c_main;

  &:hover {
    text-decoration: underline;
  }

  > i {
    font-size: 0.8em;
  }
}

.as-news-box__footer {
  z-index: 1;
  position: relative;
  text-align: center;
  padding: 20px;
  box-shadow: 0 0 30px 20px #fff;
}

.as-news-box__button {
  padding: 5px 30px;
  font-size: 1.2em;
}