.as-search-panel__title {
  margin: 0 0 15px;
  text-align: center;
  font-size: 2em;
  color: $as-c_main;
}

.as-search-panel__box {
  height: 75px;
  padding: 18px 10px;
  border-radius: 6px;
  background: $as-c_main;
  @include respond-to(tablet) {
    height: auto;
  }
}

.as-search-panel__form {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;

  @include respond-to(tablet) {
    flex-direction: column;
    
    .as-form__submit {
      display: block;
    }
  }


  > * {
    margin: 0 8px;

    @include respond-to(tablet) {
      margin: 4px 0;
      height: 40px;
    }
  }
}

.as-search-panel__separator {
  align-self: center;
  font-size: 1.2em;
  font-weight: 600;
  color: #fff;
  
  @include respond-to(tablet) {
    display: none;
  }
}