html, body {
  display: flex;
  flex-direction: column;
	min-height: 100vh;
}

.as-main {
  flex: 1 0 auto;
  padding: $as-g__header-height 0 25px;

  @include respond-to(medium-screens) {
    padding: $as-g__header-height_mobile 0 25px;
  }
}

.as-g__wrapper,
.as-g__content-wrapper {
  position: relative;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

.as-g__wrapper {
  max-width: $as-g__max-width;
}

.as-g__content-wrapper {
  max-width: $as-g__content-max-width;
}

.as-g__page_padding {
  padding: 50px 0;

  @include respond-to(small-tablet){
    padding: 40px 0;
  }
}