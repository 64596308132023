.as-content {
  font-size: 1.05em;
  line-height: 1.4em;

  @include respond-to(tablet) {
    font-size: 1em;
  }

  iframe {
    border-width: 0;
  }
  
  p {
    margin-top: 0;
    margin: 0.5em 0;
  }
  
  a {
    color: $as-c_href;
    font-weight: 700;
    text-decoration: underline;
    
    &:hover {
        color: $as-c_href_hover;
    }
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    
    th {
      font-size: 1.2em;
      border-bottom: 2px solid $as-c_line_light;
    }
    
    td {
      padding: 7px;
      border-bottom: 1px solid $as-c_line_light;
      border-left: 1px solid $as-c_line_light;
      
      &:last-child {
        border-left-color: transparent;
      }
      
      p {
        margin-bottom: 5px;
      }
    }
  }  
}