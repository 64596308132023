.as-form__row {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.as-form__row_margin {
  @extend .as-form__row;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 10px;
}

.as-form__row_margin_end {
  @extend .as-form__row_margin;
  align-items: flex-end;
}

.as-form__row_margin_center {
  @extend .as-form__row_margin;
  align-items: center;
}

.as-form__row_wrap {
  @extend .as-form__row;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.as-form__row_submit {
  @extend .as-form__row;
  justify-content: flex-end;

  @include respond-to(tablet) {
    justify-content: center;
  }
}

.as-form__cell {
  position: relative;
  margin: 0 10px;
}

.as-form__cell_grow {
  @extend .as-form__cell;
  width: 100%;
  flex-grow: 1;
}

.as-form__cell_nowrap {
  @extend .as-form__cell;
  white-space: nowrap;
}

.as-form__link {
  display: block;
  color: #7d7d7d;

  &:hover {
    text-decoration: underline;
  }
}

.as-form__label {
  white-space: nowrap;
  padding: 0 5px;
  text-align: left;
  font-weight: 600;
  font-size: 1.2em;
  color: $as-c_main;
}

.as-form__input, .as-form__select {
  min-width: 225px;
  width: 100%;
  border: none;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 1.1em;
  border-radius: 5px;
  padding: 6px 10px;
  background: #fff;
  -webkit-appearance: none;

  @include respond-to(small-tablet) {
    min-width: 175px;
  }
}


.as-form__select {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  height: 100%;
  padding: 8px 10px 8px 50px;
  background-image: url('data:image/svg+xml;utf8,\
  <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"\
	  x="0px" y="0px" viewBox="0 0 29.4 29.4" xml:space="preserve">\
	  <path fill="#{$as-c_main}" d="M14.7,0C6.6,0,0,6.6,0,14.7s6.6,14.7,14.7,14.7s14.7-6.6,14.7-14.7S22.8,0,14.7,0C14.7,0,14.7,0,14.7,0z\
		  M24.9,11.4l-9.7,9.7c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2l-9.7-9.7c-0.2-0.4-0.1-0.8,0.3-1c0.2-0.1,0.5-0.1,0.7,0\
      l9.2,9.2l9.2-9.2c0.3-0.3,0.7-0.3,1,0C25.2,10.6,25.2,11.1,24.9,11.4z"/>\
  </svg>\
  ');
  background-position: 10px 50%;
  background-size: 15px;
  background-repeat: no-repeat;
}

.as-form__submit {
  padding: 5px 30px;
  font-size: 1.3em;
  border-radius: 5px;
}