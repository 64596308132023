.as-logos-menu {
  display: flex;
  height: 100%;
}

.as-logos-menu-item {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 25px;
  text-align: center;

  &:hover {
    .as-logos-menu-item__image {
      opacity: 1;
    }
  }
}

.as-logos-menu-item__image {
  opacity: 0.8;
  height: 100%;
  display: block;
  transition: ease .15s;
}