.as-button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  text-align: center;
  font-size: 1em;
  font-weight: 600;
  border-radius: 100px;
  padding: 3px 5px;
  color: #333;
  background: #ddd;
  -webkit-appearance: none;

  &:active {
    color: #333;
  }
}

.as-button__icon {
  margin: 0 3px;
}

.as-button__label {
  margin: 0 3px;
  font-weight: 600;
}

.as-button_primary {
  @extend .as-button;
  color: #fff;
  background: darken($as-c_main_dark, 5%);
  transition: ease .1s;

  &:hover {
    background: darken($as-c_main_dark, 10%);
  }

  &:active {
    color: #fff;
  }
}