.as-actions-bar {
  display: flex;
  height: 100%;
}

.as-actions-bar-item {
  position: relative;
  height: 100%;

  @include respond-to(small-tablet) {
    position: static;
  }

  &.as-active {
    .as-actions-bar-item__btn {
      color: #fff;
      background: $as-c_main;
    }
    .as-actions-bar-item__panel {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }
}

.as-actions-bar-item__btn {
  cursor: pointer;
  padding: 0 20px;
  height: 100%;
  text-align: center;
  color: $as-c_main_dark;
  background: transparent;
  border: none;
  transition: ease .15s;

  &:hover {
    background: #F2F2F2;
  }

  @include respond-to(handhelds) {
    font-size: 0.9em;
    padding: 0 10px;
  }
}

.as-actions-bar-item__icon {
  display: inline-block;
  font-size: 1.5em;
  margin-bottom: 2px;
}

.as-actions-bar-item__panel {
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  padding: 15px;
  background: $as-c_main;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  transform: translate(0, -10px);
  transition: .2s;
}