@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff ttf svg) {
	$src: null;
	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);
	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);
	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}
	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@mixin clearfix {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

@mixin center-element {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin vertical-align-element {
	display: inline-block;
	vertical-align: middle;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -0.25em;
	}
}

@mixin circle-element($size) {
	width: $size;
	height: $size;
	border-radius: $size;
	line-height: $size;
}

@mixin reset-ul {
	list-style: none;
	margin: 0;
	padding: 0;
}