.as-slider {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.as-slider-item {
  overflow: hidden;
  position: relative;
  width: 100%;

  @include respond-to(small-tablet) {
    padding-bottom: 50%;
  }

  @include respond-to(handhelds) {
    padding-bottom: 75%;
  }
}

.as-slider-item__image {
  width: 100%;

  @include respond-to(small-tablet) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translate(-50%, -50%);
  }
}

.as-slider__btn_next,
.as-slider__btn_prev {
  opacity: 0.8;
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 50%;
  background: $as-c_main;
  @include circle-element(125px);
  transition: ease .15s;

  &:hover {
    opacity: 1;
    @include circle-element(150px);
  }

  @include respond-to(tablet) {
    @include circle-element(70px);

    &:hover {
      @include circle-element(80px);
    }
  }
}

.as-slider__btn_prev {
  left: 0;
  text-align: right;
  transform: translate(-50%, -50%);
}

.as-slider__btn_next {
  right: 0;
  text-align: left;
  transform: translate(50%, -50%);
}

.as-slider__btn__icon {
  color: #fff;
  padding: 0 25px;
  font-size: 1.7em;

  @include respond-to(tablet) {
    font-size: 1.4em;
    padding: 0 10px;
  }
}