.as-btn {
	cursor: pointer;
	display: inline-block;
	font-family: $as-font_family;
	font-weight: 400;
	font-size: 1.15em;
	text-decoration: none;
	border: none;
	border-radius: 100px;
	margin: 5px;
	padding: 7px 30px;
	text-align: center;
	color: $as-c_font;
	background: $as-c_font_revert;
	transition: ease .15s;
					
	@include respond-to(handhelds) {
		padding: 8px 35px;
		font-size: 1em;
	}
					
	@include respond-to(small-handhelds) {
		padding: 6px 35px;
		margin: 3px;
	}
					
	&:hover {
		color: $as-c_font;
		background: darken($as-c_font_revert, 5%);
	}
}