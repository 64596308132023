.as-strip-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 5px;
  border-bottom: 1px solid $as-c_main;
}

.as-strip-header__title {
  width: 100%;
  margin: 0;
  font-size: 2em;
  color: $as-c_main;

  @include respond-to(handhelds) {
    font-size: 1.7em;
  }
}

.as-strip-header-btn  {
  white-space: nowrap;
  
  @include respond-to(handhelds) {
    font-size: 0.9em;
  }

  .as-button__icon {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
    font-size: 0.7em;
  }
}