.as-input {
  margin: 8px 0;
  
  @include respond-to(small-tablet){
    margin: 6px 0;
  }
}

.as-input__label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.1em;
  &.as-required {
    &:before {
      content: '*';
      color: $as-c_main_dark;
    }
  }

  @include respond-to(small-tablet){
    font-size: 1em;
  }
}

.as-input__text,
.as-input__textarea {
  width: 100%;
  border: 1px solid $as-c_line_light;
  transition: ease .15s;

  &:hover {
    border-color: darken($as-c_line_light, 15%);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
  }

  &:focus {
    color: darken($as-c_main_dark, 10%);
    border-color: $as-c_main_dark;
    box-shadow: 0 0 3px 0 rgba($as-c_main_dark, 0.15);
  }
}

.as-input__text {
  min-height: 35px;

  @include respond-to(small-tablet){
    min-height: 25px;
  }
}

.as-input__textarea {
  resize: none;
  min-height: 85px;

  @include respond-to(small-tablet){
    min-height: 70px;
  }
}