.as-newsletter-box {
  width: 100%;
  height: 100%;
  padding: 10px 50px;
  border-radius: 10px;
  background: lighten($as-c_main, 47.5%);

  @include respond-to(tablet) {
    padding: 10px;
  }
}

.as-newsletter-box__header {
  padding: 25px 50px;
  text-align: center;
  border-bottom: 1px solid #CCCCCC;

  @include respond-to(tablet) {
    padding: 15px;
  }
}

.as-newsletter-box__icon {
  display: inline-block;
}

.as-newsletter-box__title {
  margin: 5px 0;
  font-size: 1.6em;
  color: $as-c_main_dark;
}

.as-newsletter-box__content {
  margin: 0;
}

.as-newsletter-box__form {
  padding: 25px 50px;

  @include respond-to(tablet) {
    padding: 15px;

    .as-form__row {
      flex-wrap: wrap;
    }

    .as-form__label {
      padding: 0 0 5px 0;
      text-align: right;
    }
  }

  .as-form__input {
    border: 1px solid #CCCCCC;
  }

  .as-form__label {
    width: 300px;
  }
}