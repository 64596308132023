.as-home-hugim-strip {
  margin: 25px 0;
}

.as-home-hugim-strip-box {
  display: flex;
  justify-content: space-between;
  height: 480px;
  margin: 0 -15px;

  @include respond-to(tablet) {
    flex-wrap: wrap;
    height: auto;
    margin: 0 -10px;
  }
}

.as-home-hugim-strip-box__item {
  width: 25%;
  height: 100%;
  padding: 25px 15px 0;

  @include respond-to(tablet) {
    width: 50%;
    height: auto;
    padding: 20px 10px 0;
  }
  @include respond-to(handhelds) {
    width: 100%;
  }
}