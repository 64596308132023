.as-footer {
  width: 100%;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
}

.as-footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 0;
}

.as-footer-cell {
  flex-grow: 1;
  align-self: flex-start;
  padding: 0 5px;

  @include respond-to(tablet){
    width: 100%;
    margin: 5px 0;
    text-align: center;
  }
}

.as-footer-title {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 1.7em;
  color: $as-c_main_dark;
}

.as-footer-content {
  color: #8c8c8c;
  font-size: 1.1em;
  font-weight: 600;
}

.as-footer-btn {
  color: #8c8c8c;

  &:hover {
    text-decoration: underline;
  }
}

.as-footer-social-btn {
  display: inline-block;
  margin: 0 2px;
  text-align: center;
  font-size: 1.1em;
  color: #828282;
  @include circle-element(40px);
  transition: ease .15s;

  &:hover {
    color: #464646;
    background: #E7E7E7;
  }
}

.as-footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.as-footer-menu__item {
  padding: 4px 0;
}

.as-footer-menu__link {
  font-weight: 700;
}

.as-footer-logos-menu {
  display: flex;
  height: 40px;

  @include respond-to(tablet){
    justify-content: center;
  }
}

.as-footer-logos-menu__item {
  height: 100%;
  opacity: 0.8;
  transition: ease .15s;

  &:hover {
    opacity: 1;
  }
}

.as-footer-logos-menu__image {
  height: 100%;
}

.as-footer-copyrights {
  direction: ltr;
  text-align: left;
  width: 100%;
  font-size: 1em;
  color: #333;

  > * {
    vertical-align: middle;
  }

  @include respond-to(tablet) {
    text-align: center;
  }
}

.as-footer-copyrights__symbol {
  font-weight: 600;
}

.as-footer-copyrights__logo {
  display: inline-block;
  width: 60px;
}

.as-footer-scrolltop-btn {
  cursor: pointer;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0;
  margin: 20px;
  border: none;
  color: #fff;
  background: $as-c_main;
  transition: ease .15s;

  &:hover {
    background: darken($as-c_main, 5%);
  }

  &:before {
    @extend .fi;
    content: $fi-down-arrow;
    font-size: 1.4em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
  }
}